module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Speedy Janitorial","short_name":"SpeedyJanitorial","start_url":"/","description":"Let’s take a quick look at the professional cleaning service Ottawa that\n\t\t\t\thelps in clearing all the things whether it’s move in or move out or after\n\t\t\t\tconstruction.","lang":"en","background_color":"#ffffff","theme_color":"#0ABDE3","display":"standalone","icon":"src/images/icon.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"924b0f7582018ebe850c2b7a996e1402"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-NMW6C1FWXL"],"gtagConfig":{"optimize_id":"OPT_CONTAINER_ID","anonymize_ip":false,"cookie_expires":0},"pluginConfig":{"head":true,"respectDNT":true,"delayOnRouteUpdate":0,"exclude":[],"origin":"https://www.googletagmanager.com"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
